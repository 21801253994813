// import mockData from "@/store/mock.js";
import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range";
export default {
	calcAmount (arr = [], key = "basketQt") {
		return arr.reduce((cnt, el) => {
			if (el[key]) {
				return el[key] * Number(el.price) + cnt;
			}
			return cnt;
		}, 0);
	},
	// example quantity 1 || quantity 2-4 ||  quantity >= 5
	getPluralPhrase (str, n) {
		const arr = str.split("/");
		const idx = customRule(n);
		const current = arr[idx] || "";
		return current.replaceAll("%n", n);
	},
	prepareMainRestaurantInfo (data = {}) {
		if ("settings" in data) {
			data.workScheduleAvailable = Boolean(data.settings?.length);
			data.settings = data.settings.sort((a, b) => a.dayOfWeek - b.dayOfWeek);
		}
		if ("contacts" in data && data.contacts.length) {
			data.contacts = data.contacts
				.sort((a, b) => {
					if (a.value.startsWith("Кон") && !b.value.startsWith("Кон")) {
						return -1;
					}
					else {
						return 1;
					}
				})
				.map(item => {
					!item.type && (item.type = window.$.i18n.global.t("phone"));
					return item;
				});
		}
		data.menus = this.configurateMenuSectionSchedule(data.menus);

		// restaurant working schedule
		if (data?.settings) {
			data.settings = this.updateScheduleObject(data.settings);
		}

		return data;
	},
	configurateMenuSectionSchedule (menus) {
		let menuArray = [];
		menus.forEach(m => {
			/** sync fields for menu objects from different api */
			m.title = m.name || m.title;
			m.name = m.title || m.name;

			if (!m?.schedule) {
				m.isVisible = true;
				m.isOrderAvailable = true;
				m.scheduleMessage = null;
			}
			else {
				m.schedule = this.updateScheduleObject(m.schedule).sort((a, b) => a.dayOfWeek - b.dayOfWeek);
				let currentMenuSectionSchedule = this.findCurrentTimeScheduleRange(m.schedule);
				let isMenuSectionActiveNow = currentMenuSectionSchedule?.active || false;
				m.isOrderAvailable = isMenuSectionActiveNow;
				m.isVisible = isMenuSectionActiveNow || m.showOutsideSchedule;
				if (!m.isVisible) {
					m.scheduleMessage = null;
				}
				else {
					m.scheduleMessage = isMenuSectionActiveNow ?
						this.getShortDateValue(currentMenuSectionSchedule) :
						this.findNextWorkingDate(m.schedule);
				}
			}

			if (m.isVisible) {
				menuArray.push(m);
			}
		});
		return menuArray;
	},
	setPaymentInfo (state, data = {}) {
		state.payment.ccy = {
			country: data.country,
			currency: data.currency,
			currencySymbol: currencySymbol(data.currency),
		};
		if (!state.payment.info) {
			state.payment.info = Object.assign({}, {
				restaurantId: data.restaurantId,
				name: data.restaurantName,
				tableNumber: data.tableNumber,
			});
		}
		state.payment.tipPercents = data.tipPercents;
		state.payment.orders = [...data.orders];
	},
	setInvoiceInfo (state, data = {}) {
		console.log("data", data);
		state.payment.invoice = {
			amount: data.amount,
			currency: data.currency,
			currencySymbol: currencySymbol(data.currency),
			time: new Date(data.time),
			feedbackLink: data.feedbackLink,
			tableCode: data.tableCode,
			restaurantId: data.restaurantId,
		};
	},
	mutateActiveOrders (state, {data = []}) {
		state.activeOrders = {};
		data.forEach(el => state.activeOrders[el.orderNumber] = Object.assign({}, el));
	},
	mutateAllMenuData (state, {data = {}, menuId}) {

		let newData = {
			directory: {},
		};
		let menuSectionData = {
			directory: {},
		};
		newData.directory.allergens = data.allergens;
		newData.directory.tags = data.tags;

		menuSectionData.directory.allergens = data.allergens;
		menuSectionData.directory.tags = data.tags;

		// add additional data
		const uniqSpecial = new Set();
		(data.dishes || []).forEach(el => {
			el.categoryName = data.categories.find(item => item.id === el.categoryId).name;
			if (el.tags) {
				el.tags = (el.tags).map(item => {
					const currentTag = data.tags.find(el2 => el2.id === item.id);
					if (currentTag) {
						item.name = currentTag.name;
						item.bgColor = currentTag.bgColor;
						if (currentTag.bgColor) {
							item.color = this.blackOrWhite(currentTag.bgColor); // "#FC42A6";
						}
					}
					if (currentTag?.iconUrl) {
						item.iconUrl = currentTag.iconUrl;
						// item.color = "#FC42A6";
					}
					// color: "#FFF6E4",
					return item;
				});
			}
			if (el.specials) {
				el.specials.forEach(sp => uniqSpecial.add(sp));
			}
			if (el.allergens?.length) {
				if (!el.tags) {
					el.tags = [];
				}
				el.tags.push({
					// name: "Аллергены!!!!",
					name: window.$.i18n.global.t("allergens"),
					isAllergens: true,
					color: data.allergenOptions.backgroundUrl || "#F9E6E5",
					options: el.allergens.map(alergenId => data.allergens.find(el2 => el2.id === alergenId).name),
					allergenOptions: data.allergenOptions,
				});
			}
		});
		newData.menuListOptions = data.categories || [];
		newData.headMenuItems = data.categories || [];
		newData.specials = data.specials || [];
		newData.dishes = data.dishes || [];

		menuSectionData.specials = data.specials || [];
		(state.restaurant.menus || []).forEach(el => {
			state.resMenu[el.id] = {};
			Object.assign(state.resMenu[el.id], menuSectionData);

			const uniqCategory = new Set();
			// data.dishes.forEach(d => uniqCategory.add(d.categoryId));
			state.resMenu[el.id].dishes = (data.dishes || []).filter(dish => {
				if (dish.menuId === el.id) {
					uniqCategory.add(dish.categoryId);
				}
				return dish.menuId === el.id;
			});
			const filteredCategories = (data.categories || []).filter(cur => uniqCategory.has(cur.id));
			state.resMenu[el.id].menuListOptions = filteredCategories;
			state.resMenu[el.id].headMenuItems = filteredCategories;
		});

		state.emptyDishImage = data.emptyDishImage;
		Object.assign(state.resMenu, newData);
		state.restaurant.menuId = menuId;
	},
	mutateAvailableLangs (state, {data = []}) {
		state.availableLangs = [];
		data.forEach(el => {
			if (el.code === "UK") {
				el.title = "UA";
			}
			else {el.title = el.code;}
			el.id = el.title.toLowerCase();
			state.availableLangs.push(el);
		});
	},
	getNumberDifference (num1, num2) {
		return Number(num1) - Number(num2);
	},
	getCurrentTimezoneMomentDate () {
		return moment().tz("Europe/Kyiv");
	},
	getMomentDateByTime (time = "00:00") {
		const timeArray = time.split(":");
		return this.getCurrentTimezoneMomentDate().hours(Number(timeArray[0])).minutes(Number(timeArray[1])).second(0);
		// return today({hour: timeArray[0], minute: timeArray[1]});
	},
	updateScheduleObject (schedule) {
		schedule.forEach(el => {
			el.dateStart = this.getCurrentTimezoneMomentDate().isoWeekday(el.dayOfWeek).hours(Number(el.startTime.split(":")[0])).minutes(Number(el.startTime.split(":")[1])).second(0);
			el.dateEnd = this.getCurrentTimezoneMomentDate().isoWeekday(el.dayOfWeek).hours(Number(el.endTime.split(":")[0])).minutes(Number(el.endTime.split(":")[1])).second(0);
			let isEndTimeNextDay = el.dateStart.unix() - el.dateEnd.unix();

			if (isEndTimeNextDay >= 0) {
				el.dateEnd = el.dateEnd.add(1, "day");
			}
			// console.log("el", el.dayOfWeek);
			// console.log("el", el.dateStart.format());
			// console.log("el", el.dateEnd.format());
		});
		return schedule;
	},
	isMomentTimeInRange ({start, end}) {
		// let startMoment = this.getMomentDateByTime(start);
		// let endMoment = this.getMomentDateByTime(end);
		// let isEndTimeNextDay = startMoment.unix() - endMoment.unix();
		// if (isEndTimeNextDay >= 0) {
		// 	startMoment = startMoment.subtract(1, "day");
		// }
		// return this.getCurrentTimezoneMomentDate().isBetween(startMoment, endMoment);
		// const isTimeBetween = function(startTime, endTime, serverTime) {

		const now = Date.now();
		const today = new Date();
		const yymmdd = [today.getUTCFullYear(), today.getMonth(), today.getDate()];
		const [startTime, endTime] = [
			new Date(...yymmdd, ...start.split(":")).getTime(),
			new Date(...yymmdd, ...end.split(":")).getTime(),
		];

		let actualStartTimestamp = startTime;
		let actualEndTimestamp = endTime;

		if (startTime > endTime) {
			if (startTime > now) { // 21:00 - 12:11
				actualStartTimestamp -= 86400000;
			}
			else {
				if (now > endTime) { //  13:00 - 12:11
					actualEndTimestamp += 86400000;
				}
			}
		}
		// 12:01 - 12:11  09:00 - 12:11  11:40 - 20:21
		return now < actualEndTimestamp && now > actualStartTimestamp;
	},
	findCurrentTimeScheduleRange (schedule) {
		return schedule.find(el => el.active && this.getCurrentTimezoneMomentDate().isBetween(el.dateStart, el.dateEnd));
	},
	getShortDateValue (dateWorkingSettings) {
		if (!dateWorkingSettings) {
			return null;
		}
		if (dateWorkingSettings.dayOfWeek === moment().isoWeekday()) {
			return {
				day: "today",
				time: dateWorkingSettings.startTime,
				timeEnd: dateWorkingSettings.endTime,
			};
		}
		else if (dateWorkingSettings.dayOfWeek === moment().add(1, "day").isoWeekday()) {
			return {
				day: "tomorrow",
				time: dateWorkingSettings.startTime,
				timeEnd: dateWorkingSettings.endTime,
			};
		}
		else {
			return {
				day: dateWorkingSettings.dayOfWeek,
				time: dateWorkingSettings.startTime,
				timeEnd: dateWorkingSettings.endTime,
			};
		}
	},
	findNextWorkingDate (schedule, isScheduleAvailable = true) {
		let nextWorkingDay;
		if (!schedule || !isScheduleAvailable) {
			return;
		}
		// check if resto will open today
		const todaySettings = schedule.find(el => el.dayOfWeek === this.getCurrentTimezoneMomentDate().isoWeekday());
		const restoStartMoment = this.getMomentDateByTime(todaySettings.startTime);
		const isNowTimeBefore = this.getCurrentTimezoneMomentDate().isBefore(restoStartMoment);
		if (isNowTimeBefore && todaySettings?.active) {
			nextWorkingDay = todaySettings;
			return this.getShortDateValue(nextWorkingDay);
		}
		// find next available day
		const todayTimeIndex = schedule.findIndex(el => el.dayOfWeek === this.getCurrentTimezoneMomentDate().isoWeekday());
		const nextDays = schedule.slice(todayTimeIndex + 1);
		const prevDays = schedule.slice(0, todayTimeIndex);

		nextWorkingDay = nextDays.find(el => el.active);
		nextWorkingDay = nextWorkingDay || prevDays.find(el => el.active);
		return this.getShortDateValue(nextWorkingDay);
	},
	blackOrWhite (bg) {
		let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(bg);
		const r = parseInt(result[1], 16);
		const g = parseInt(result[2], 16);
		const b = parseInt(result[1], 16);
		const c = r * g * b;
		const base = 16777215; // ffffff
		return c < base / 2 ? "#ffffff" : "#000000";
	},
	animateCounter (element, targetNumber, duration) {
		let currentNumber = 0;
		const frameDuration = 1000 / 60;
		const totalFrames = Math.round(duration / frameDuration);
		const increment = targetNumber / totalFrames;

		const counter = setInterval(() => {
			currentNumber += increment;
			element.innerHTML = Math.round(currentNumber);

			if (Math.round(currentNumber) >= targetNumber) {
				clearInterval(counter);
				element.innerHTML = targetNumber;
			}
		}, frameDuration);
		return counter;
	},
	scrollElementToTop (top = 0, elemId = "app") {
		window && document.getElementById(elemId).scrollTo({top, behavior: "smooth"});
	},
	formatUkrainianDateRange (d1, d2) {
		const date1 = new Date(d1);
		const date2 = new Date(d2);

		const optionsTime = {hour: "2-digit", minute: "2-digit"};
		const optionsDate = {day: "numeric", month: "long"};

		const today = new Date();
		const tomorrow = new Date();
		tomorrow.setDate(today.getDate() + 1);

		const isSameDay = date1.toDateString() === date2.toDateString();
		const isToday = date1.toDateString() === today.toDateString();
		const isTomorrow = date1.toDateString() === tomorrow.toDateString();

		const time1 = date1.toLocaleTimeString("uk-UA", optionsTime);
		const time2 = date2.toLocaleTimeString("uk-UA", optionsTime);

		if (isSameDay) {
			if (isToday) {
				return `Сьогодні з ${time1} до ${time2}`;
			}
			else if (isTomorrow) {
				return `Завтра з ${time1} до ${time2}`;
			}
			else {
				const formattedDate = date1.toLocaleDateString("uk-UA", optionsDate);
				return `${formattedDate} з ${time1} до ${time2}`;
			}
		}
		else {
			const formattedDate1 = date1.toLocaleDateString("uk-UA", optionsDate);
			const formattedDate2 = date2.toLocaleDateString("uk-UA", optionsDate);
			return `${formattedDate1} з ${time1} до ${formattedDate2} ${time2}`;
		}
	},
};

function customRule (n) {
	if (n === 0 || n >= 5) {
		return 2;
	}
	if (n === 2 || n === 3 || n === 4) {
		return 1;
	}
	if (n === 1) {
		return 0;
	}
}
function isMoreUpperThan (value, percent = 50) {
	const words = value.split(" ");
	for (let i = 0; i < words.length; i++) {
		if ((((words[i].match(/[A-ZА-Я]/g) || []).length / words[i].length) * 100) >= percent) {
			return true;
		}
	}
	return false;
}
function normalizeCase (value, percent = 25) {
	const words = value.split(" ");
	for (let i = 0; i < words.length; i++) {
		if ((((words[i].match(/[A-ZА-Я]/g) || []).length / words[i].length) * 100) >= percent) {
			words[i] = words[i].charAt(0).toUpperCase() + words[i].toLowerCase().slice(1);
		}
	}
	return words.join(" ");
}
function currencySymbol (value) {
	const data = {
		"UAH": "₴",
		"EUR": "€",
		"USD": "$",
		"PLN": "zł",
	};
	if (!value) {return "";}
	return data[value];
}
