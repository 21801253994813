export const API_STATUS = {
	IDLE: Symbol("IDLE"),
	PENDING: Symbol("PENDING"),
	READY: Symbol("READY"),
	ERROR: Symbol("ERROR"),
	EMPTY: Symbol("EMPTY"),
	OUTOFSTOCK: Symbol("OUTOFSTOCK"),
	INVOICEERROR: Symbol("INVOICEERROR"),
};

export const ORDER_STATUS = {
	NEW: "NEW",
	CONFIRMED: "CONFIRMED",
	COOKING: "COOKING",
	DONE: "DONE",
	DELIVERY: "DELIVERY",
	CLOSED: "CLOSED",
	DECLINED: "DECLINED",
};

export const DELIVERY_OPTIONS = {
	DELIVERY: "DELIVERY",
	PICKUP: "PICKUP",
	RESTAURANT: "IN_RESTAURANT",
	NONE: "NONE",
};

export const INVOICE_STATUS = {
	CREATED: "CREATED",
	PROCESSING: "PROCESSING",
	HOLD: "HOLD",
	SUCCESS: "SUCCESS",
	REVERSED: "REVERSED",
	FAILURE: "FAILURE",
	EXPIRED: "EXPIRED",
};

export const MENU_TYPE = {
	WISH: "wish",
	DELIVERY: "delivery",
	TABLE_ORDER: "table-order",
};

export const BASKET_TYPE = {
	IN_RESTAURANT: "IN_RESTAURANT",
	DELIVERY: "DELIVERY",
};

export const ERROR_CODE = {
	PRODUCTS_RESTRICTIONS_ERROR: "PRODUCTS_RESTRICTIONS_ERROR",
	DELIVERY_DISTANCE_ERROR: "DELIVERY_DISTANCE_ERROR",
	PAYMENT_NOT_ALLOWED_ERROR: "PAYMENT_NOT_ALLOWED_ERROR",
};

export const RESTAURANT_STATUS = {
	ACTIVE: "ACTIVE",
	DISABLED: "DISABLED",
};

export const PROPOSITION_STATUS = {
	ACTIVE: "ACTIVE",
	EXPIRED: "EXPIRED",
	SOLD_OUT: "SOLD_OUT",
};

export const MAX_TIP_ABSOLUTE = 14999;
export const MIN_TIP_ORDER = 100;
