<template>
	<div class="modal-wrapper" />
	<router-view
		v-slot="{ Component }"
	>
		<component :is="Component" />
	</router-view>
	<Head>
		<title>{{ pageTitle }}</title>
		<link
			rel="canonical"
			:href="canonicalUrl"
		/>
		<meta
			name="description"
			:content="pageDescription"
		/>
		<meta
			name="theme-color"
			:content="themeColor"
			media="(prefers-color-scheme: dark)"
		/>
		<meta
			name="theme-color"
			:content="themeColor"
			media="(prefers-color-scheme: light)"
		/>
		<link
			rel="preconnect"
			href="https://www.googletagmanager.com"
		/>
		<link
			rel="icon"
			type="image/png"
			:href="icon"
		/>
		<html
			:lang="page.locale"
			:data-os="$?.ua?.os?.name"
		/>
		<body
			:data-theme="page.theme"
			data-fixinput
		/>
	</Head>
</template>

<script setup>
import {watch, computed, onMounted, provide, onUnmounted} from "vue";
import {Head} from "@vueuse/head";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";
import {getRouteMeta} from "#app/vue-common/router.js";
import {useState as usePageState} from "#app/store/page.js";
import colorHelpers from "#app/utils/color-helpers.js";
import {useLangCtrl} from "@/i18n.js";

const {t} = useI18n({useScope: "global"});
provide("t", t);

const route = useRoute();
const page = usePageState();
const {setLocale} = useLangCtrl();
page.ignoreStatic = localStorage?.ignoreStatic === "true"; /* Global ignore static flag */

const pageTitle = computed(() => [page?.restaurant.title || "", route.meta.title || ""].filter($ => $).join(" - "));
const pageDescription = computed(() => {
	const descr = page?.restaurant?.description || "";
	return descr.replace(/(\<(\/?[^>]+)>)/g, "");
});
const themeColor = computed(() => {
	return page.themeColor;
});
const icon = computed(() => page.restaurant.iconUrl || "/expz-favicon-96x96.png");
const menuSettings = computed(() => {
	return page.restaurant?.menuSettings || {};
});


const canonicalUrl = computed(() => {
	const url = new URL(route.fullPath, "https://expz.menu/");
	return url.toString();
});
provide("canonicalUrl", canonicalUrl);

const routeMeta = computed(() => getRouteMeta(route));
provide("routeMeta", routeMeta);

let restaurantOpenInterval;

function initWindowSize (page) {
	const typeNames = ["isMobile", "isDesktop"];
	typeNames.forEach(type => page[type] = false);
	if (window.innerWidth < 1024) {
		page.isMobile = true;
	}
	else {
		page.isDesktop = true;
	}
	const doc = document.documentElement;
	doc.style.setProperty("--app-height", `${window.innerHeight}px`);
}

watch(menuSettings, initMenuBaseActionColor, {deep: true});

function initMenuBaseActionColor () {
	const accentHexColor = menuSettings.value?.accentHexColor;
	const fontHexColor = menuSettings.value?.fontHexColor;

	if (accentHexColor && fontHexColor) {
		const doc = document.documentElement;
		doc.style.setProperty("--action-color", accentHexColor);
		doc.style.setProperty("--action-text-color", fontHexColor);
		doc.style.setProperty("--action-light-color", colorHelpers.convertHexToRGBA(accentHexColor, 0.15)); // colorHelpers.lightColor(accentHexColor))
		doc.style.setProperty("--action-hover-color", colorHelpers.convertHexToRGBA(accentHexColor, 1));
		doc.style.setProperty("--action-transparent-color", colorHelpers.convertHexToRGBA(accentHexColor, 8));
	}
}

onMounted(() => {
	/** Language setup for mobile app **/
	let locale = route.query?.lang || 'uk';
	switch (locale.toLowerCase()) {
		case 'en':
			setLocale('en');
			break;
		default:
			setLocale('uk');
	}

	initWindowSize(page);

	window.addEventListener("resize", (e) => {
		initWindowSize(page);
	});

	page.checkIsRestaurantOpen();
	restaurantOpenInterval = setInterval(() => {
		page.checkIsRestaurantOpen();
	}, 10000);

});

onUnmounted(() => {
	if (restaurantOpenInterval) {
		clearInterval(restaurantOpenInterval);
	}
});
</script>
<style lang="less">
@import "#app/assets/less/font.less";
@import "#app/assets/less/var.less";
@import "#app/assets/less/animation.less";
@import "#app/assets/less/mixin.less";

*,
*::after,
*::before {
	box-sizing: border-box;
	background: none;
	color: inherit;
	padding: 0;
	margin: 0;
	outline: none;
	border: none;
	text-decoration: none;
	box-shadow: none;
	vertical-align: inherit;
	min-height: 0;
	min-width: 0;
	font: inherit;
	-webkit-font-smoothing: antialiased;
	-webkit-tap-highlight-color: transparent;
}
*::-webkit-scrollbar {
	display: none;
	scrollbar-width: none;
	width: 2px;
}

*::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.2);
}
h1, h2, h3, h4, h5  {
	&[id] {
		scroll-margin-top: 2em;
	}
}

html, body {
	// background: #f6f6f6;
}

body{
	font-family: "Lato", sans-serif;
	font-size: 12px;
	font-weight: 400;
	line-height: 140%;
	color: @color-black;
	height: var(--app-height);
	min-height: var(--app-height);
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	-webkit-box-flex: 1;
	overflow: hidden;
	&.open-modal{
		overflow: hidden;
		position: fixed;
	}
}

main {
	// height: 100%;
	// min-height: 100%;
	display: flex;
	flex-direction: column;
	flex: 1;
	-webkit-box-flex: 1;
	flex-shrink: 0;
	@media(min-width: @width--tablet-min) {
		// height: 100%;
		// min-height: 100%;
		// display: unset;
	}
}

// .layout{
// 	width: 100%;
// 	min-height: 100%;
// 	display: flex;
// 	flex-direction: column;
// 	flex-grow: 1;
// 	flex-shrink: 0;
// 	// max-width: 375px;
// 	// margin: 0 auto;
// 	// @media(min-width: @width--mobile-max) {
// 	// 	max-width: 768px;
// 	// }
// 	@media(min-width: @width--tablet-min) {
// 		// max-width: 100%;
// 		// min-height: unset;
// 		// height: unset;
// 		// min-height: unset;
// 		// display: unset;
// 	}
// 	// @media(min-width: @width--desktop-min) {
// 	// 	max-width: 100%;
// 	// }
// 	.layout-content{
// 		flex-shrink: 0;

// 		@media(min-width: @width--tablet-min) {
// 			max-width: 920px;
// 			width: 100%;
// 			height: 100%;
// 			margin: 0 auto;
// 			display: flex;
// 			flex-wrap: wrap;
// 			align-items: flex-start;
// 		}
// 		padding: 0 24px;
// 		position: relative;
// 	}
// }
h1.title{
	font-weight: 800;
	font-size: 20px;
	line-height: 24px;
}
h2.title{
	font-weight: 800;
	font-size: 20px;
	line-height: 24px;
}

h3.title{
	font-weight: 800;
	font-size: 18px;
	line-height: 24px;
}
h4.title{
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
}
@supports(-webkit-appearance: none) or (-moz-appearance: none) {
	input[type='radio'] {
		--active: var(--action-color);
		--active-inner: #fff;
		//--focus: 2px rgba(39, 94, 254, .3);
		--border: #CCCCCC;
		--border-hover: var(--action-color);
		--background: #fff;
		--disabled: #F6F8FF;
		--disabled-inner: var(--action-light-color);
		-webkit-appearance: none;
		-moz-appearance: none;
		height: 24px;
		width: 24px;
		outline: none;
		display: inline-block;
		vertical-align: top;
		position: relative;
		margin: 0;
		cursor: pointer;
		border: 1px solid var(--bc, var(--border));
		background: var(--b, var(--background));
		transition: background .3s, border-color .3s, box-shadow .2s;
		&:after {
			content: '';
			display: block;
			left: 0;
			top: 0;
			position: absolute;
			transition: transform 0.6s cubic-bezier(.2, .85, .32, 1.2), opacity 0.3s;
		}
		&:checked {
			--b: var(--active-inner);
			--bc: var(--active);
		}
		&:disabled {
			--b: var(--disabled);
			cursor: not-allowed;
			opacity: .9;
			&:checked {
				--b: var(--disabled-inner);
				--bc: var(--border);
			}
		}
		&:hover {
			&:not(:checked) {
				&:not(:disabled) {
					--bc: var(--border);
				}
			}
		}
		/*&:focus {
			box-shadow: 0 0 0 var(--focus);
		}*/
		&:not(.switch) {
			width: 24px;
			&:after {
				opacity: var(--o, 0);
			}
			&:checked {
				--o: 1;
			}
		}
	}
	input[type='radio'] {
		border-radius: 50%;
		&:after {
			width: 100%;
			height: 100%;
			border-radius: 50%;
			background: var(--active);
			opacity: 0;
			transform: scale(var(--s, .6));
		}
		&:checked {
			--s: .5;
		}
	}
}
.from-bottom-animate{
	animation: from-bottom-animate 300ms linear;
}
@keyframes from-bottom-animate {
	from{
		transform: translateY(30px);
	}
	to{
		transform: translateY(0px);
	}
}
.order-list .order-list-item:last-child .menu-list-item{
	border-bottom: none;
}
.radio-list label.radio-item:last-child{
	border-bottom: none;
}
.clickable{
	cursor: pointer;
}
.decorate-supported-text{
	white-space:pre-wrap;
	strong, b {
		font-weight: bold;
	}
	u {
		text-decoration: underline;
	}
	em {
		font-style: italic;
	}
	a:link {
		text-decoration: underline;
		cursor: auto;
	}
	a:visited {
		text-decoration: underline;
		cursor: auto;
	}
	blockquote {
		display: block;
		margin-top: 1em;
		margin-bottom: 1em;
		margin-left: 40px;
		margin-right: 40px;
	}
	q {
		display: inline;
	}
	q::before {
		content: open-quote;
	}
	q::after {
		content: close-quote;
	}
	hr {
		display: block;
		margin-top: 0.5em;
		margin-bottom: 0.5em;
		margin-left: auto;
		margin-right: auto;
		border-style: inset;
		border-width: 1px;
	}
	li {
		display: list-item;
	}
	mark {
		background-color: yellow;
		color: black;
	}
	ol {
		display: block;
		list-style-type: decimal;
		margin-top: 1em;
		margin-bottom: 1em;
		margin-left: 0;
		margin-right: 0;
		padding-left: 40px;
	}
	ul {
		display: block;
		list-style-type: disc;
		margin-top: 1em;
		margin-bottom: 1 em;
		margin-left: 0;
		margin-right: 0;
		padding-left: 40px;
	}
}

.modal-wrapper {
	position: fixed;
	z-index: @appIndex;
	top: 0;
	left: 0;
	width: 100%;
}
:root {
    --app-height: 100vh;
	--special-height: 0px;
	// set default color variable
	--action-color: @color-purple;
	--action-light-color: @color-light-purple;
	--action-hover-color: @color-purple--hover;
	--action-transparent-color: @color-purple-transparent;
	--action-text-color: @color-white;
}
.full-width {
	width: 100%;
}
#observer-space {
	display: block;
	height: 32px;
}
.observer-space {
	display: block;
	height: 32px;
}
</style>

